import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date time scalar */
  DateTime: any;
};

export type Account = {
  __typename?: 'Account';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  numberOfSubAccounts: Scalars['Int'];
  profileImg?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userName: Scalars['String'];
};

export type CreateOrderResponse = {
  __typename?: 'CreateOrderResponse';
  createOrder?: Maybe<Position>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Login = {
  __typename?: 'Login';
  accessToken: Scalars['String'];
  user: Account;
  userRole: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  createOrder?: Maybe<CreateOrderResponse>;
  createSubAccount: MutationResponse;
  createUser?: Maybe<Account>;
  deleteAccount?: Maybe<MutationResponse>;
  deleteSubAccount?: Maybe<MutationResponse>;
  login?: Maybe<Login>;
  toggleNextTradeShutdown?: Maybe<MutationResponse>;
  toggleSubaccount?: Maybe<MutationResponse>;
  toggleUserStatus: Scalars['String'];
  updateUser: MutationResponse;
};


export type MutationCreateOrderArgs = {
  activeQuantity: Scalars['Float'];
  buyAboveLiquidationPrice: Scalars['Float'];
  derivatives: Scalars['String'];
  ladderPercent: Scalars['Float'];
  leverage: Scalars['Float'];
  minSellPercent: Scalars['Float'];
  nextTradeShutdown: Scalars['Boolean'];
  numOfCycles: Scalars['Int'];
  percentDropPerLadder: Scalars['Float'];
  sellPointDropPercent: Scalars['Float'];
  sellPointDropTimeline: Scalars['Float'];
  sellPointDropTimelineUnit: TimelineUnit;
  sellPointPercent: Scalars['Float'];
  setNumOfCycles?: InputMaybe<Scalars['Boolean']>;
  subAccountId: Scalars['Int'];
  symbol: Scalars['String'];
};


export type MutationCreateSubAccountArgs = {
  byBitKey: Scalars['String'];
  byBitSecret: Scalars['String'];
  leverage: Scalars['Float'];
  subAccountName: Scalars['String'];
};


export type MutationCreateUserArgs = {
  active: Scalars['Boolean'];
  email: Scalars['String'];
  password: Scalars['String'];
  profileImg?: InputMaybe<Scalars['String']>;
  userName: Scalars['String'];
};


export type MutationDeleteAccountArgs = {
  accountId: Scalars['Int'];
};


export type MutationDeleteSubAccountArgs = {
  subAccountId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationToggleNextTradeShutdownArgs = {
  flag: Scalars['Boolean'];
  positionId: Scalars['Int'];
};


export type MutationToggleSubaccountArgs = {
  subAccountId: Scalars['Int'];
};


export type MutationToggleUserStatusArgs = {
  status: Scalars['Boolean'];
  userId: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  profileImg?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
  userName: Scalars['String'];
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Position = {
  __typename?: 'Position';
  activeQuantity?: Maybe<Scalars['Float']>;
  antiLiquidationPrice?: Maybe<Scalars['Float']>;
  entryPrice?: Maybe<Scalars['Float']>;
  exitPrice?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  leverage?: Maybe<Scalars['Float']>;
  liquidationPrice?: Maybe<Scalars['Float']>;
  nextTradeShutdown?: Maybe<Scalars['Boolean']>;
  numOfCycles?: Maybe<Scalars['Int']>;
  subAccountId?: Maybe<Scalars['Int']>;
  symbol?: Maybe<Scalars['String']>;
  unrealisedPnl?: Maybe<Scalars['Float']>;
  walletBalance?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  accessToken: Scalars['String'];
  account?: Maybe<Account>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  getPositionsBySubAccount?: Maybe<Array<Maybe<Position>>>;
  getSubAccountPositionBySymbol?: Maybe<Position>;
  getSymbols?: Maybe<Array<Maybe<Symbols>>>;
  isLoggedIn: Scalars['Boolean'];
  subAccount?: Maybe<SubAccount>;
  subAccounts: Array<Maybe<SubAccount>>;
  userRole: Scalars['String'];
  viewer: Account;
};


export type QueryAccountArgs = {
  userId: Scalars['Int'];
};


export type QueryGetPositionsBySubAccountArgs = {
  subAccountId: Scalars['Int'];
};


export type QueryGetSubAccountPositionBySymbolArgs = {
  subAccountId: Scalars['Int'];
  symbol: Scalars['String'];
};


export type QuerySubAccountArgs = {
  subAccountId: Scalars['Int'];
};

export type SubAccount = {
  __typename?: 'SubAccount';
  accountId: Scalars['Int'];
  active: Scalars['Boolean'];
  byBitKey: Scalars['String'];
  byBitSecret: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  leverage: Scalars['Float'];
  subAccountName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']>;
};

export type SymbolLotSizeFilter = {
  __typename?: 'SymbolLotSizeFilter';
  maxTradeQuantity?: Maybe<Scalars['Float']>;
  minTradeQuanity?: Maybe<Scalars['Float']>;
  quantityStep?: Maybe<Scalars['Float']>;
};

export type SymbolPriceFilter = {
  __typename?: 'SymbolPriceFilter';
  maxPrice?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
  tickSize?: Maybe<Scalars['Float']>;
};

export type Symbols = {
  __typename?: 'Symbols';
  alias: Scalars['String'];
  lotSizeFilter: SymbolLotSizeFilter;
  name: Scalars['String'];
  priceFilter: SymbolPriceFilter;
};

export enum TimelineUnit {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Second = 'SECOND',
  Week = 'WEEK'
}

export type CreateOrder = {
  __typename?: 'createOrder';
  buyAboveLiquidationPrice: Scalars['Float'];
  derivatives: Scalars['String'];
  ladderPercent: Scalars['Float'];
  leverage: Scalars['Float'];
  minSellPercent: Scalars['Float'];
  nextTradeShutdown: Scalars['Boolean'];
  numOfCycles: Scalars['Int'];
  percentDropPerLadder: Scalars['Float'];
  quantity: Scalars['Float'];
  sellPointDropPercent: Scalars['Float'];
  sellPointDropTimeline: Scalars['Float'];
  sellPointpercent: Scalars['Float'];
  subAccountId: Scalars['Int'];
  symbol: Scalars['String'];
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'Login', accessToken: string, userRole: string, user: { __typename?: 'Account', id: string, userName: string, email: string, active: boolean, createdAt: any, profileImg?: string | null, updatedAt: any } } | null };

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String'];
  userName: Scalars['String'];
  active: Scalars['Boolean'];
  password: Scalars['String'];
  profileImg?: InputMaybe<Scalars['String']>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'Account', id: string, userName: string, email: string, profileImg?: string | null, active: boolean, createdAt: any, updatedAt: any } | null };

export type ToggleUserStatusMutationVariables = Exact<{
  userId: Scalars['ID'];
  status: Scalars['Boolean'];
}>;


export type ToggleUserStatusMutation = { __typename?: 'Mutation', toggleUserStatus: string };

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  userName: Scalars['String'];
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'MutationResponse', success: boolean, message: string } };

export type DeleteAccountMutationVariables = Exact<{
  accountId: Scalars['Int'];
}>;


export type DeleteAccountMutation = { __typename?: 'Mutation', deleteAccount?: { __typename?: 'MutationResponse', success: boolean, message: string } | null };

export type CreateOrderMutationVariables = Exact<{
  symbol: Scalars['String'];
  subAccountId: Scalars['Int'];
  sellPointPercent: Scalars['Float'];
  activeQuantity: Scalars['Float'];
  minSellPercent: Scalars['Float'];
  derivatives: Scalars['String'];
  percentDropPerLadder: Scalars['Float'];
  sellPointDropTimeline: Scalars['Float'];
  sellPointDropTimelineUnit: TimelineUnit;
  sellPointDropPercent: Scalars['Float'];
  ladderPercent: Scalars['Float'];
  buyAboveLiquidationPrice: Scalars['Float'];
  nextTradeShutdown: Scalars['Boolean'];
  numOfCycles: Scalars['Int'];
  leverage: Scalars['Float'];
  setNumOfCycles?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder?: { __typename?: 'CreateOrderResponse', success: boolean, message: string } | null };

export type ToggleNextTradeShutdownMutationVariables = Exact<{
  positionId: Scalars['Int'];
  flag: Scalars['Boolean'];
}>;


export type ToggleNextTradeShutdownMutation = { __typename?: 'Mutation', toggleNextTradeShutdown?: { __typename?: 'MutationResponse', success: boolean, message: string } | null };

export type CreateSubAccountMutationVariables = Exact<{
  leverage: Scalars['Float'];
  subAccountName: Scalars['String'];
  byBitKey: Scalars['String'];
  byBitSecret: Scalars['String'];
}>;


export type CreateSubAccountMutation = { __typename?: 'Mutation', createSubAccount: { __typename?: 'MutationResponse', success: boolean, message: string } };

export type ToggleSubAccountMutationVariables = Exact<{
  subAccountId: Scalars['Int'];
}>;


export type ToggleSubAccountMutation = { __typename?: 'Mutation', toggleSubaccount?: { __typename?: 'MutationResponse', success: boolean, message: string } | null };

export type DeleteSubAccountMutationVariables = Exact<{
  subAccountId: Scalars['Int'];
  userId: Scalars['Int'];
}>;


export type DeleteSubAccountMutation = { __typename?: 'Mutation', deleteSubAccount?: { __typename?: 'MutationResponse', success: boolean, message: string } | null };

export type IsLoggedInQueryVariables = Exact<{ [key: string]: never; }>;


export type IsLoggedInQuery = { __typename?: 'Query', isLoggedIn: boolean };

export type UserRoleQueryVariables = Exact<{ [key: string]: never; }>;


export type UserRoleQuery = { __typename?: 'Query', userRole: string };

export type AccessTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type AccessTokenQuery = { __typename?: 'Query', accessToken: string };

export type AccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsQuery = { __typename?: 'Query', accounts?: Array<{ __typename?: 'Account', id: string, userName: string, email: string, profileImg?: string | null, active: boolean, numberOfSubAccounts: number, updatedAt: any, createdAt: any } | null> | null };

export type AccountQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type AccountQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, email: string, userName: string, profileImg?: string | null } | null };

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = { __typename?: 'Query', viewer: { __typename?: 'Account', userName: string, email: string, profileImg?: string | null } };

export type GetSymbolsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSymbolsQuery = { __typename?: 'Query', getSymbols?: Array<{ __typename?: 'Symbols', name: string, alias: string, lotSizeFilter: { __typename?: 'SymbolLotSizeFilter', maxTradeQuantity?: number | null, minTradeQuanity?: number | null, quantityStep?: number | null }, priceFilter: { __typename?: 'SymbolPriceFilter', minPrice?: number | null, maxPrice?: number | null, tickSize?: number | null } } | null> | null };

export type GetPositionsBySubAccountQueryVariables = Exact<{
  subAccountId: Scalars['Int'];
}>;


export type GetPositionsBySubAccountQuery = { __typename?: 'Query', getPositionsBySubAccount?: Array<{ __typename?: 'Position', id?: number | null, symbol?: string | null, leverage?: number | null, subAccountId?: number | null, entryPrice?: number | null, antiLiquidationPrice?: number | null, exitPrice?: number | null, unrealisedPnl?: number | null, walletBalance?: number | null, activeQuantity?: number | null, nextTradeShutdown?: boolean | null } | null> | null };

export type SubAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type SubAccountsQuery = { __typename?: 'Query', subAccounts: Array<{ __typename?: 'SubAccount', id: string, accountId: number, leverage: number, subAccountName: string, byBitKey: string, byBitSecret: string, createdAt: any, updatedAt: any } | null> };

export type SubAccountsActiveQueryVariables = Exact<{ [key: string]: never; }>;


export type SubAccountsActiveQuery = { __typename?: 'Query', subAccounts: Array<{ __typename?: 'SubAccount', active: boolean } | null> };

export type SubAccountLeverageQueryVariables = Exact<{
  subAccountId: Scalars['Int'];
}>;


export type SubAccountLeverageQuery = { __typename?: 'Query', subAccount?: { __typename?: 'SubAccount', leverage: number } | null };


export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user {
      id
      userName
      email
      active
      createdAt
      profileImg
      updatedAt
    }
    accessToken
    userRole
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($email: String!, $userName: String!, $active: Boolean!, $password: String!, $profileImg: String) {
  createUser(
    email: $email
    userName: $userName
    active: $active
    password: $password
    profileImg: $profileImg
  ) {
    id
    userName
    email
    profileImg
    active
    createdAt
    updatedAt
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      userName: // value for 'userName'
 *      active: // value for 'active'
 *      password: // value for 'password'
 *      profileImg: // value for 'profileImg'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const ToggleUserStatusDocument = gql`
    mutation ToggleUserStatus($userId: ID!, $status: Boolean!) {
  toggleUserStatus(userId: $userId, status: $status)
}
    `;
export type ToggleUserStatusMutationFn = Apollo.MutationFunction<ToggleUserStatusMutation, ToggleUserStatusMutationVariables>;

/**
 * __useToggleUserStatusMutation__
 *
 * To run a mutation, you first call `useToggleUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserStatusMutation, { data, loading, error }] = useToggleUserStatusMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleUserStatusMutation(baseOptions?: Apollo.MutationHookOptions<ToggleUserStatusMutation, ToggleUserStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleUserStatusMutation, ToggleUserStatusMutationVariables>(ToggleUserStatusDocument, options);
      }
export type ToggleUserStatusMutationHookResult = ReturnType<typeof useToggleUserStatusMutation>;
export type ToggleUserStatusMutationResult = Apollo.MutationResult<ToggleUserStatusMutation>;
export type ToggleUserStatusMutationOptions = Apollo.BaseMutationOptions<ToggleUserStatusMutation, ToggleUserStatusMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($userId: ID!, $userName: String!, $email: String!, $password: String) {
  updateUser(
    userId: $userId
    userName: $userName
    email: $email
    password: $password
  ) {
    success
    message
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userName: // value for 'userName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount($accountId: Int!) {
  deleteAccount(accountId: $accountId) {
    success
    message
  }
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($symbol: String!, $subAccountId: Int!, $sellPointPercent: Float!, $activeQuantity: Float!, $minSellPercent: Float!, $derivatives: String!, $percentDropPerLadder: Float!, $sellPointDropTimeline: Float!, $sellPointDropTimelineUnit: TimelineUnit!, $sellPointDropPercent: Float!, $ladderPercent: Float!, $buyAboveLiquidationPrice: Float!, $nextTradeShutdown: Boolean!, $numOfCycles: Int!, $leverage: Float!, $setNumOfCycles: Boolean) {
  createOrder(
    symbol: $symbol
    subAccountId: $subAccountId
    sellPointPercent: $sellPointPercent
    activeQuantity: $activeQuantity
    minSellPercent: $minSellPercent
    derivatives: $derivatives
    percentDropPerLadder: $percentDropPerLadder
    sellPointDropTimeline: $sellPointDropTimeline
    sellPointDropTimelineUnit: $sellPointDropTimelineUnit
    sellPointDropPercent: $sellPointDropPercent
    ladderPercent: $ladderPercent
    buyAboveLiquidationPrice: $buyAboveLiquidationPrice
    nextTradeShutdown: $nextTradeShutdown
    numOfCycles: $numOfCycles
    leverage: $leverage
    setNumOfCycles: $setNumOfCycles
  ) {
    success
    message
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      symbol: // value for 'symbol'
 *      subAccountId: // value for 'subAccountId'
 *      sellPointPercent: // value for 'sellPointPercent'
 *      activeQuantity: // value for 'activeQuantity'
 *      minSellPercent: // value for 'minSellPercent'
 *      derivatives: // value for 'derivatives'
 *      percentDropPerLadder: // value for 'percentDropPerLadder'
 *      sellPointDropTimeline: // value for 'sellPointDropTimeline'
 *      sellPointDropTimelineUnit: // value for 'sellPointDropTimelineUnit'
 *      sellPointDropPercent: // value for 'sellPointDropPercent'
 *      ladderPercent: // value for 'ladderPercent'
 *      buyAboveLiquidationPrice: // value for 'buyAboveLiquidationPrice'
 *      nextTradeShutdown: // value for 'nextTradeShutdown'
 *      numOfCycles: // value for 'numOfCycles'
 *      leverage: // value for 'leverage'
 *      setNumOfCycles: // value for 'setNumOfCycles'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const ToggleNextTradeShutdownDocument = gql`
    mutation ToggleNextTradeShutdown($positionId: Int!, $flag: Boolean!) {
  toggleNextTradeShutdown(positionId: $positionId, flag: $flag) {
    success
    message
  }
}
    `;
export type ToggleNextTradeShutdownMutationFn = Apollo.MutationFunction<ToggleNextTradeShutdownMutation, ToggleNextTradeShutdownMutationVariables>;

/**
 * __useToggleNextTradeShutdownMutation__
 *
 * To run a mutation, you first call `useToggleNextTradeShutdownMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleNextTradeShutdownMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleNextTradeShutdownMutation, { data, loading, error }] = useToggleNextTradeShutdownMutation({
 *   variables: {
 *      positionId: // value for 'positionId'
 *      flag: // value for 'flag'
 *   },
 * });
 */
export function useToggleNextTradeShutdownMutation(baseOptions?: Apollo.MutationHookOptions<ToggleNextTradeShutdownMutation, ToggleNextTradeShutdownMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleNextTradeShutdownMutation, ToggleNextTradeShutdownMutationVariables>(ToggleNextTradeShutdownDocument, options);
      }
export type ToggleNextTradeShutdownMutationHookResult = ReturnType<typeof useToggleNextTradeShutdownMutation>;
export type ToggleNextTradeShutdownMutationResult = Apollo.MutationResult<ToggleNextTradeShutdownMutation>;
export type ToggleNextTradeShutdownMutationOptions = Apollo.BaseMutationOptions<ToggleNextTradeShutdownMutation, ToggleNextTradeShutdownMutationVariables>;
export const CreateSubAccountDocument = gql`
    mutation CreateSubAccount($leverage: Float!, $subAccountName: String!, $byBitKey: String!, $byBitSecret: String!) {
  createSubAccount(
    leverage: $leverage
    subAccountName: $subAccountName
    byBitKey: $byBitKey
    byBitSecret: $byBitSecret
  ) {
    success
    message
  }
}
    `;
export type CreateSubAccountMutationFn = Apollo.MutationFunction<CreateSubAccountMutation, CreateSubAccountMutationVariables>;

/**
 * __useCreateSubAccountMutation__
 *
 * To run a mutation, you first call `useCreateSubAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubAccountMutation, { data, loading, error }] = useCreateSubAccountMutation({
 *   variables: {
 *      leverage: // value for 'leverage'
 *      subAccountName: // value for 'subAccountName'
 *      byBitKey: // value for 'byBitKey'
 *      byBitSecret: // value for 'byBitSecret'
 *   },
 * });
 */
export function useCreateSubAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubAccountMutation, CreateSubAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubAccountMutation, CreateSubAccountMutationVariables>(CreateSubAccountDocument, options);
      }
export type CreateSubAccountMutationHookResult = ReturnType<typeof useCreateSubAccountMutation>;
export type CreateSubAccountMutationResult = Apollo.MutationResult<CreateSubAccountMutation>;
export type CreateSubAccountMutationOptions = Apollo.BaseMutationOptions<CreateSubAccountMutation, CreateSubAccountMutationVariables>;
export const ToggleSubAccountDocument = gql`
    mutation ToggleSubAccount($subAccountId: Int!) {
  toggleSubaccount(subAccountId: $subAccountId) {
    success
    message
  }
}
    `;
export type ToggleSubAccountMutationFn = Apollo.MutationFunction<ToggleSubAccountMutation, ToggleSubAccountMutationVariables>;

/**
 * __useToggleSubAccountMutation__
 *
 * To run a mutation, you first call `useToggleSubAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSubAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSubAccountMutation, { data, loading, error }] = useToggleSubAccountMutation({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useToggleSubAccountMutation(baseOptions?: Apollo.MutationHookOptions<ToggleSubAccountMutation, ToggleSubAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleSubAccountMutation, ToggleSubAccountMutationVariables>(ToggleSubAccountDocument, options);
      }
export type ToggleSubAccountMutationHookResult = ReturnType<typeof useToggleSubAccountMutation>;
export type ToggleSubAccountMutationResult = Apollo.MutationResult<ToggleSubAccountMutation>;
export type ToggleSubAccountMutationOptions = Apollo.BaseMutationOptions<ToggleSubAccountMutation, ToggleSubAccountMutationVariables>;
export const DeleteSubAccountDocument = gql`
    mutation DeleteSubAccount($subAccountId: Int!, $userId: Int!) {
  deleteSubAccount(subAccountId: $subAccountId, userId: $userId) {
    success
    message
  }
}
    `;
export type DeleteSubAccountMutationFn = Apollo.MutationFunction<DeleteSubAccountMutation, DeleteSubAccountMutationVariables>;

/**
 * __useDeleteSubAccountMutation__
 *
 * To run a mutation, you first call `useDeleteSubAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubAccountMutation, { data, loading, error }] = useDeleteSubAccountMutation({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteSubAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubAccountMutation, DeleteSubAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubAccountMutation, DeleteSubAccountMutationVariables>(DeleteSubAccountDocument, options);
      }
export type DeleteSubAccountMutationHookResult = ReturnType<typeof useDeleteSubAccountMutation>;
export type DeleteSubAccountMutationResult = Apollo.MutationResult<DeleteSubAccountMutation>;
export type DeleteSubAccountMutationOptions = Apollo.BaseMutationOptions<DeleteSubAccountMutation, DeleteSubAccountMutationVariables>;
export const IsLoggedInDocument = gql`
    query isLoggedIn {
  isLoggedIn @client
}
    `;

/**
 * __useIsLoggedInQuery__
 *
 * To run a query within a React component, call `useIsLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsLoggedInQuery(baseOptions?: Apollo.QueryHookOptions<IsLoggedInQuery, IsLoggedInQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(IsLoggedInDocument, options);
      }
export function useIsLoggedInLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsLoggedInQuery, IsLoggedInQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(IsLoggedInDocument, options);
        }
export type IsLoggedInQueryHookResult = ReturnType<typeof useIsLoggedInQuery>;
export type IsLoggedInLazyQueryHookResult = ReturnType<typeof useIsLoggedInLazyQuery>;
export type IsLoggedInQueryResult = Apollo.QueryResult<IsLoggedInQuery, IsLoggedInQueryVariables>;
export const UserRoleDocument = gql`
    query userRole {
  userRole @client
}
    `;

/**
 * __useUserRoleQuery__
 *
 * To run a query within a React component, call `useUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRoleQuery(baseOptions?: Apollo.QueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, options);
      }
export function useUserRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, options);
        }
export type UserRoleQueryHookResult = ReturnType<typeof useUserRoleQuery>;
export type UserRoleLazyQueryHookResult = ReturnType<typeof useUserRoleLazyQuery>;
export type UserRoleQueryResult = Apollo.QueryResult<UserRoleQuery, UserRoleQueryVariables>;
export const AccessTokenDocument = gql`
    query accessToken {
  accessToken @client
}
    `;

/**
 * __useAccessTokenQuery__
 *
 * To run a query within a React component, call `useAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessTokenQuery(baseOptions?: Apollo.QueryHookOptions<AccessTokenQuery, AccessTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccessTokenQuery, AccessTokenQueryVariables>(AccessTokenDocument, options);
      }
export function useAccessTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccessTokenQuery, AccessTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccessTokenQuery, AccessTokenQueryVariables>(AccessTokenDocument, options);
        }
export type AccessTokenQueryHookResult = ReturnType<typeof useAccessTokenQuery>;
export type AccessTokenLazyQueryHookResult = ReturnType<typeof useAccessTokenLazyQuery>;
export type AccessTokenQueryResult = Apollo.QueryResult<AccessTokenQuery, AccessTokenQueryVariables>;
export const AccountsDocument = gql`
    query Accounts {
  accounts {
    id
    userName
    email
    profileImg
    active
    numberOfSubAccounts
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const AccountDocument = gql`
    query Account($userId: Int!) {
  account(userId: $userId) {
    id
    email
    userName
    profileImg
  }
}
    `;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAccountQuery(baseOptions: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
      }
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;
export const ViewerDocument = gql`
    query Viewer {
  viewer {
    userName
    email
    profileImg
  }
}
    `;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
      }
export function useViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
        }
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<ViewerQuery, ViewerQueryVariables>;
export const GetSymbolsDocument = gql`
    query GetSymbols {
  getSymbols {
    name
    alias
    lotSizeFilter {
      maxTradeQuantity
      minTradeQuanity
      quantityStep
    }
    priceFilter {
      minPrice
      maxPrice
      tickSize
    }
  }
}
    `;

/**
 * __useGetSymbolsQuery__
 *
 * To run a query within a React component, call `useGetSymbolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSymbolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSymbolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSymbolsQuery(baseOptions?: Apollo.QueryHookOptions<GetSymbolsQuery, GetSymbolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSymbolsQuery, GetSymbolsQueryVariables>(GetSymbolsDocument, options);
      }
export function useGetSymbolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSymbolsQuery, GetSymbolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSymbolsQuery, GetSymbolsQueryVariables>(GetSymbolsDocument, options);
        }
export type GetSymbolsQueryHookResult = ReturnType<typeof useGetSymbolsQuery>;
export type GetSymbolsLazyQueryHookResult = ReturnType<typeof useGetSymbolsLazyQuery>;
export type GetSymbolsQueryResult = Apollo.QueryResult<GetSymbolsQuery, GetSymbolsQueryVariables>;
export const GetPositionsBySubAccountDocument = gql`
    query GetPositionsBySubAccount($subAccountId: Int!) {
  getPositionsBySubAccount(subAccountId: $subAccountId) {
    id
    symbol
    leverage
    subAccountId
    entryPrice
    antiLiquidationPrice
    exitPrice
    unrealisedPnl
    walletBalance
    activeQuantity
    nextTradeShutdown
  }
}
    `;

/**
 * __useGetPositionsBySubAccountQuery__
 *
 * To run a query within a React component, call `useGetPositionsBySubAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionsBySubAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionsBySubAccountQuery({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useGetPositionsBySubAccountQuery(baseOptions: Apollo.QueryHookOptions<GetPositionsBySubAccountQuery, GetPositionsBySubAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPositionsBySubAccountQuery, GetPositionsBySubAccountQueryVariables>(GetPositionsBySubAccountDocument, options);
      }
export function useGetPositionsBySubAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPositionsBySubAccountQuery, GetPositionsBySubAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPositionsBySubAccountQuery, GetPositionsBySubAccountQueryVariables>(GetPositionsBySubAccountDocument, options);
        }
export type GetPositionsBySubAccountQueryHookResult = ReturnType<typeof useGetPositionsBySubAccountQuery>;
export type GetPositionsBySubAccountLazyQueryHookResult = ReturnType<typeof useGetPositionsBySubAccountLazyQuery>;
export type GetPositionsBySubAccountQueryResult = Apollo.QueryResult<GetPositionsBySubAccountQuery, GetPositionsBySubAccountQueryVariables>;
export const SubAccountsDocument = gql`
    query SubAccounts {
  subAccounts {
    id
    accountId
    leverage
    subAccountName
    byBitKey
    byBitSecret
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useSubAccountsQuery__
 *
 * To run a query within a React component, call `useSubAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubAccountsQuery(baseOptions?: Apollo.QueryHookOptions<SubAccountsQuery, SubAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubAccountsQuery, SubAccountsQueryVariables>(SubAccountsDocument, options);
      }
export function useSubAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubAccountsQuery, SubAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubAccountsQuery, SubAccountsQueryVariables>(SubAccountsDocument, options);
        }
export type SubAccountsQueryHookResult = ReturnType<typeof useSubAccountsQuery>;
export type SubAccountsLazyQueryHookResult = ReturnType<typeof useSubAccountsLazyQuery>;
export type SubAccountsQueryResult = Apollo.QueryResult<SubAccountsQuery, SubAccountsQueryVariables>;
export const SubAccountsActiveDocument = gql`
    query SubAccountsActive {
  subAccounts {
    active
  }
}
    `;

/**
 * __useSubAccountsActiveQuery__
 *
 * To run a query within a React component, call `useSubAccountsActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountsActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountsActiveQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubAccountsActiveQuery(baseOptions?: Apollo.QueryHookOptions<SubAccountsActiveQuery, SubAccountsActiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubAccountsActiveQuery, SubAccountsActiveQueryVariables>(SubAccountsActiveDocument, options);
      }
export function useSubAccountsActiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubAccountsActiveQuery, SubAccountsActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubAccountsActiveQuery, SubAccountsActiveQueryVariables>(SubAccountsActiveDocument, options);
        }
export type SubAccountsActiveQueryHookResult = ReturnType<typeof useSubAccountsActiveQuery>;
export type SubAccountsActiveLazyQueryHookResult = ReturnType<typeof useSubAccountsActiveLazyQuery>;
export type SubAccountsActiveQueryResult = Apollo.QueryResult<SubAccountsActiveQuery, SubAccountsActiveQueryVariables>;
export const SubAccountLeverageDocument = gql`
    query SubAccountLeverage($subAccountId: Int!) {
  subAccount(subAccountId: $subAccountId) {
    leverage
  }
}
    `;

/**
 * __useSubAccountLeverageQuery__
 *
 * To run a query within a React component, call `useSubAccountLeverageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountLeverageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountLeverageQuery({
 *   variables: {
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useSubAccountLeverageQuery(baseOptions: Apollo.QueryHookOptions<SubAccountLeverageQuery, SubAccountLeverageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubAccountLeverageQuery, SubAccountLeverageQueryVariables>(SubAccountLeverageDocument, options);
      }
export function useSubAccountLeverageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubAccountLeverageQuery, SubAccountLeverageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubAccountLeverageQuery, SubAccountLeverageQueryVariables>(SubAccountLeverageDocument, options);
        }
export type SubAccountLeverageQueryHookResult = ReturnType<typeof useSubAccountLeverageQuery>;
export type SubAccountLeverageLazyQueryHookResult = ReturnType<typeof useSubAccountLeverageLazyQuery>;
export type SubAccountLeverageQueryResult = Apollo.QueryResult<SubAccountLeverageQuery, SubAccountLeverageQueryVariables>;