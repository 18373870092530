import { ReactElement, memo } from 'react';
import { StyleSheet, css } from 'aphrodite';

import { Account } from '../../api/generated';
import UserRowActions from './UserRowActions';

interface UserRowProps {
  user: Account | null;
}

function UserRow({ user }: UserRowProps): ReactElement | null {
  if (!user) {
    return null;
  }

  return (
    <tr>
      <td className={css(styles.cell)}>{user.id}</td>
      <td className={css(styles.cell)}>{user.userName}</td>
      <td className={css(styles.cell)}>{user.numberOfSubAccounts}</td>
      {/* <td className={css(styles.cell)}>{user.role}</td> */}
      {/* <td className={css(styles.cell)}>$869,137.45</td> */}
      <td className={css(styles.cell)}>{user.email}</td>
      <td className={css(styles.cell)}>
        <UserRowActions isActive={user.active} userId={user.id} />
      </td>
    </tr>
  );
}

const styles = StyleSheet.create({
  cell: {
    padding: '0.75rem 0',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export default memo(UserRow);
