import { useApolloClient } from '@apollo/client';
import { css, StyleSheet } from 'aphrodite';
import { ErrorMessage, Field, Formik } from 'formik';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  CreateSubAccountMutationVariables, useCreateSubAccountMutation
} from '../../api/generated';
import { HOME } from '../../constants/router';

const CreateSubAccountSchema = Yup.object().shape({
  leverage: Yup.number()
    .positive('Invalid')
    .max(100, 'Please enter a number less than 100')
    .required('Required'),
  subAccountName: Yup.string().required('Required'),
  byBitKey: Yup.string().required('Required'),
  byBitSecret: Yup.string().required('Required'),
});

function NewTradeForm(): ReactElement {
  const client = useApolloClient();

  const navigate = useNavigate();

  const [mutate, { loading, error }] = useCreateSubAccountMutation({
    onCompleted() {
      client.cache.evict({ fieldName: 'subAccounts' });
      client.cache.gc();
      navigate(HOME);
    },
  });

  const handleSubmit = (values: CreateSubAccountMutationVariables) => {
    mutate({ variables: values });
  };

  return (
    <div>
      <div className={`container ${css(styles.wrap)}`}>
        <div className={css(styles.container)}>
          <div className={`sub-heading ${css(styles.title)}`}>New Trade</div>
          <Formik
            initialValues={{
              leverage: 3,
              subAccountName: '',
              byBitKey: '',
              byBitSecret: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={CreateSubAccountSchema}
          >
            {({ submitForm }) => (
              <>
                <div className={css(styles.form)}>
                  <div className={css(styles.labelContainer)}>
                    <span className="input-label">Leverage</span>
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="leverage"
                    />
                  </div>
                  <Field
                    name="leverage"
                    className={`input_box ${css(styles.input)}`}
                    type="number"
                  />
                  <div className={css(styles.labelContainer)}>
                    <span className="input-label">Sub-Account Name</span>
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="subAccountName"
                    />
                  </div>
                  <Field
                    name="subAccountName"
                    className={`input_box ${css(styles.input)}`}
                    type="text"
                  />
                  <div className={css(styles.labelContainer)}>
                    <span className="input-label">ByBit API Key</span>
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="byBitKey"
                    />
                  </div>
                  <Field
                    name="byBitKey"
                    className={`input_box ${css(styles.input)}`}
                    type="text"
                  />
                  <div className={css(styles.labelContainer)}>
                    <span className="input-label">ByBit Secret Key</span>
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="byBitSecret"
                    />
                  </div>
                  <Field
                    name="byBitSecret"
                    className={`input_box ${css(styles.input)}`}
                    type="text"
                  />
                  <span className="input-label">
                    Status:
                    <span> Enabled</span>
                  </span>
                </div>
                <button
                  disabled={loading}
                  onClick={submitForm}
                  className="btn-primary"
                  type="button"
                >
                  Get Started
                </button>
                {error && (
                  <div className={`form-error ${css(styles.error)}`}>
                    {error.message}
                  </div>
                )}
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  wrap: {
    width: '35rem',
    height: '100%',
  },
  container: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: '200ms',
  },
  form: {
    marginBottom: '3rem',
    width: '100%',
  },
  input: {
    marginBottom: '2rem',
    marginTop: '0.5rem',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 25px',
  },
  error: {
    marginTop: '1rem',
  },
  title: {
    textAlign: 'center',
    marginBottom: '1.5rem',
  },
});

export default NewTradeForm;
