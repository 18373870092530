import { useApolloClient } from '@apollo/client';
import { css, StyleSheet } from 'aphrodite';
import { Field, Form, Formik } from 'formik';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginMutationVariables, useLoginMutation } from '../api/generated';
import { accessTokenVar, isLoggedInVar, userRoleVar } from '../cache';
import Background from '../components/shared/Background';
import Loader from '../components/shared/Loader';
import { HOME } from '../constants/router';
import { TOKEN, USER_ROLE } from '../constants/storageKeys';

function SignIn(): ReactElement {
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(true);
  const client = useApolloClient();

  const [mutate, { loading, error }] = useLoginMutation({
    async onCompleted({ login }) {
      if (login?.accessToken) {
        await client.resetStore();
        localStorage.setItem(TOKEN, login.accessToken);
        localStorage.setItem(USER_ROLE, login.userRole);
        isLoggedInVar(true);
        accessTokenVar(login.accessToken);
        userRoleVar(login.userRole);
        navigate(HOME);
      }
    },
  });

  const handleLogin = async (values: LoginMutationVariables) => {
    await mutate({ variables: values });
  };
  const validate = (values: LoginMutationVariables) => {
    if (
      values.email !== ''
      && values.password !== ''
      && values.password.length > 5
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };
  return (
    <Background>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={handleLogin}
        validate={validate}
      >
        {({ submitForm }) => (
          <div className={css(style.signin_container)}>
            <span className={`heading ${css(style.label)}`}>Sign In</span>
            <Form>
              <div className={`container ${css(style.container)}`}>
                <Field
                  className={`input_box ${css(style.input)}`}
                  type="text"
                  placeholder="Username"
                  name="email"
                />
                <Field
                  className={`input_box ${css(style.input)}`}
                  type="password"
                  placeholder="Password"
                  name="password"
                />
                {error && <div>{error.message}</div>}
                <button
                  disabled={disableButton}
                  onClick={submitForm}
                  className="btn-primary"
                  type="submit"
                  style={{
                    height: loading ? 'auto' : 'auto',
                    padding: loading ? '3px 37px' : '10px 30px',
                    borderRadius: loading ? '3rem' : '3rem',
                  }}
                >
                  {loading ? <Loader /> : 'Get Started'}
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </Background>
  );
}

const style = StyleSheet.create({
  container: {
    height: 'contain',
    padding: '52px 32px',
    width: '406px',
  },
  signin_container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    zIndex: 20,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
  },
  input: {
    marginBottom: '30px',
  },
  label: {
    marginBottom: '81px',
  },
});

export default SignIn;
