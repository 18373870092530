import { StyleSheet, css } from 'aphrodite';
import { memo, useState } from 'react';

import { IoChevronDown } from 'react-icons/io5';
import PositionTable from './PositionTable';
import { SubAccount } from '../../api/generated';
import SubAccountActions from './SubAccountActions';
import { colors } from '../../constants/colors';
import { createOrder } from '../../constants/router';
import { useNavigate } from 'react-router-dom';

interface StatusRowProps {
  row: SubAccount | any;
  active: boolean | undefined
}

function StatusRow({ row, active }: StatusRowProps) {
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleNavigate = () => {
    navigate(createOrder(row?.id));
  };

  return (
    <div className={css(styles.main)}>
      <SubAccountActions
        active={active}
        subAccountId={Number(row?.id)}
        userId={Number(row?.accountId)}
      />
      <div
        className={css(
          styles.container,
          isExpanded && styles.expandedContainer
        )}
      >
        <button
          type="button"
          className={css(styles.row)}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span className={css(styles.text, styles.leverage)}>
            {`Leverage: ${row?.leverage}x`}
          </span>
          <span className={css(styles.text, styles.date)}>
            {`Date Created: ${new Date(row?.createdAt).toLocaleDateString()}`}
          </span>
          <span className={css(styles.text, styles.account)}>
            {`Sub-Account Name: ${row?.subAccountName}`}
          </span>
          <button
            onClick={handleNavigate}
            className="btn-primary"
            type="button"
          >
            New Trade
          </button>
          <IoChevronDown
            className={css(styles.icon, isExpanded && styles.activeIcon)}
          />
        </button>
        {isExpanded && (
          <PositionTable subAccountId={parseInt(row?.id || '0')} />
        )}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  main: {
    borderBottom: '1px solid #34384C',
    display: 'flex',
    color: colors.textSecondary,
    alignItems: 'flex-start',
  },
  container: {
    flex: 1,
  },
  expandedContainer: {
    color: colors.white,
  },
  row: {
    display: 'flex',
    padding: '1rem 0.5rem',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: colors.hover,
    },
  },
  text: {
    margin: '0 1.5rem',
    textAlign: 'left',
  },
  leverage: {
    width: '10%',
  },
  date: {
    width: '20%',
  },
  account: {
    width: '30%',
  },
  walletTable: {
    padding: '2rem 4rem',
    display: 'none',
  },
  expandedTable: {
    display: 'block',
  },
  walletRow: {
    display: 'flex',
    alignItems: 'center',
    ':not(:last-child)': {
      marginBottom: '1rem',
    },
  },
  headerText: {
    fontWeight: 'bold',
  },
  rowItem: {
    width: '20%',
  },
  detailsBtn: {
    marginLeft: 'auto',
  },
  success: {
    color: colors.green,
    '::before': {
      content: "'+'",
    },
    '::after': {
      content: "'%'",
    },
  },
  danger: {
    color: colors.danger,
    '::after': {
      content: "'%'",
    },
  },
  icon: {
    transform: 'rotate(0deg)',
    transition: 'transform 200ms',
  },
  activeIcon: {
    transform: 'rotate(-180deg)',
  },
});

export default memo(StatusRow);
