import {
  Position,
  useToggleNextTradeShutdownMutation
} from '../../api/generated';
import { ReactElement, memo, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';

import { colors } from '../../constants/colors';

interface PositionRowProps {
  position: Position | any;
}

function PositionRow({ position }: PositionRowProps): ReactElement {
  const [active, setActive] = useState(position?.nextTradeShutdown);

  const [mutate, { loading }] = useToggleNextTradeShutdownMutation({
    onError(error) {
      console.error(error);
      setActive(!active);
    },
  });

  const handleToggle = async (e: any) => {
    if (loading) {
      return;
    }

    setActive(!active);

    await mutate({
      variables: {
        positionId: position.id,
        flag: e.target.checked,
      },
    });
  };

  return (
    <div className={css(styles.walletRow)} key={position?.symbol}>
      <span className={css(styles.rowItem)}>{position?.symbol}</span>
      <span className={css(styles.rowItem, styles.decimals)}>
        {position?.activeQuantity?.toFixed(5)}
      </span>
      <span className={css(styles.rowItem, styles.decimals)}>
        {position?.entryPrice?.toFixed(5) || '-'}
      </span>
      <span className={css(styles.rowItem, styles.decimals)}>
        {position?.antiLiquidationPrice?.toFixed(5) || '-'}
      </span>
      <span className={css(styles.rowItem, styles.decimals)}>
        {position?.exitPrice?.toFixed(5) || '-'}
      </span>
      {/* <span
        className={css(
          styles.rowItem,
          position?.unrealisedPnl && position?.unrealisedPnl > 0
            ? styles.success
            : styles.danger,
          styles.decimals
        )}
      >
        {position?.unrealisedPnl?.toFixed(10)}
      </span> */}
      <span className={css(styles.rowItem, styles.alignItem)}>
        <div className={css(styles.bottom_sec)}>
          <label className="switch">
            <input
              name="nextTradeShutdown"
              type="checkbox"
              onChange={handleToggle}
              checked={active}
            />
            <span className="slider round" />
          </label>
          {/* <span className={css(styles.txt)}>Next Trade ShutDown</span> */}
        </div>
      </span>
      {/* <button
        className={`btn-secondary btn-sm ${css(styles.detailsBtn)}`}
        type="button"
      >
        Details
      </button> */}
    </div>
    // </Formik>
  );
}

const styles = StyleSheet.create({
  walletRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 0',
    ':hover': {
      backgroundColor: colors.hover,
    },
  },
  detailsBtn: {
    marginLeft: 'auto',
  },
  success: {
    color: colors.green,
    '::before': {
      content: "'+'",
    },
    '::after': {
      content: "'%'",
    },
  },
  danger: {
    color: colors.danger,
    '::after': {
      content: "'%'",
    },
  },
  rowItem: {
    flex: 1,
    padding: '0 20px',
  },
  decimals: {
    textAlign: 'right',
  },
  bottom_sec: {
    // marginBottom: '5rem',
  },
  txt: {
    fontSize: '18px',
    lineHeight: '27px',
    color: '#A5ADCF',
    marginLeft: '11px',
  },
  alignItem: {
    textAlign: 'center',
  },
});

export default memo(PositionRow);
