import { useApolloClient } from '@apollo/client';
import { css, StyleSheet } from 'aphrodite';
import { ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useAccountQuery, useUpdateUserMutation } from '../api/generated';
import ProfileForm from '../components/trade/ProfileForm';
import { HOME } from '../constants/router';

const UpdateUserSchema = Yup.object({
  userName: Yup.string().required('Required'),
  email: Yup.string().email('Please enter a valid email').required('Required'),
  password: Yup.string()
    .matches(
      /[@$!%*#?&]+/,
      'Password must contain atleast one special character'
    )
    .min(8, 'Password must be atleast 8 characters long'),
});

function UpdateUser(): ReactElement {
  const navigate = useNavigate();
  const params = useParams();

  const client = useApolloClient();

  const { data } = useAccountQuery({
    variables: { userId: parseInt(params.userId || '', 10) },
  });

  const [mutate, { loading, error }] = useUpdateUserMutation({
    onCompleted() {
      navigate(HOME);
      client.cache.evict({ fieldName: 'accounts' });
      client.cache.evict({ fieldName: 'account' });
      client.cache.gc();
    },
  });

  return (
    <div>
      <div className={`container ${css(styles.container)}`}>
        <div className={`sub-heading ${css(styles.title)}`}>Update User</div>
        {data?.account && (
          <ProfileForm
            user={data?.account}
            validationSchema={UpdateUserSchema}
            isCreate={false}
            mutate={mutate}
            error={error}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '35rem',
    height: '100%',
  },
  title: {
    textAlign: 'center',
    marginBottom: '1.5rem',
  },
});

export default UpdateUser;
