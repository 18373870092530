import { CREATE_USER, HOME, NEW_TRADE } from '../../constants/router';
import {
  IoAppsOutline,
  IoLogOutOutline,
  IoPersonAddOutline
} from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';

import Logo from './Logo';
import { logout } from '../../utils/logout';
import { useApolloClient } from '@apollo/client';
import { useUserRoleQuery } from '../../api/generated';

function Navbar() {
  const { data } = useUserRoleQuery();

  const client = useApolloClient();

  const { pathname } = useLocation();

  const handleLogout = async () => {
    try {
      client.cache.evict({ fieldName: 'accessToken' });
      client.cache.evict({ fieldName: 'userRole' });
      client.cache.evict({ fieldName: 'isLoggedIn' });
      client.cache.gc();
      await client.resetStore();
    } catch (error) {
      console.error(error);
    } finally {
      logout();
    }
  };

  return (
    <nav className="navbar">
      <ul className="navbar-nav">
        <li className="logo">
          <Link to={HOME} className="nav-link">
            <span className="link-text">Crypto Bot</span>
            <Logo />
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={HOME}
            className={`nav-link ${pathname === HOME && 'nav-active'}`}
          >
            <span className="active-dot" />
            <IoAppsOutline size={25} />
            <span className="link-text">
              {data?.userRole === 'admin' ? 'Users' : 'Accounts'}
            </span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={data?.userRole === 'admin' ? CREATE_USER : NEW_TRADE}
            className={`nav-link ${
              (pathname === CREATE_USER || pathname === NEW_TRADE)
              && 'nav-active'
            }`}
          >
            <span className="active-dot" />
            <IoPersonAddOutline size={25} />
            <span className="link-text">
              {data?.userRole === 'admin' ? 'New User' : 'Add Account'}
            </span>
          </Link>
        </li>
        {/* {data?.userRole === 'admin' && (
          <>
            <li className="nav-item">
              <Link
                to={WALLET}
                className={`nav-link ${pathname === WALLET && 'nav-active'}`}
              >
                <span className="active-dot" />
                <IoWalletOutline size={25} />
                <span className="link-text">Wallet</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={SUB_ACCOUNT_STATUS}
                className={`nav-link ${
                  pathname === SUB_ACCOUNT_STATUS && 'nav-active'
                }`}
              >
                <span className="active-dot" />
                <IoSettingsOutline size={25} />
                <span className="link-text">Sub-Account Status</span>
              </Link>
            </li>
          </>
        )} */}
        <li className="nav-item">
          <button onClick={handleLogout} type="button" className="nav-link">
            <IoLogOutOutline size={25} />
            <span className="link-text">Logout</span>
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
