import { StyleSheet, css } from 'aphrodite';

import { ReactElement } from 'react';
import UserRow from './UserRow';
import { colors } from '../../constants/colors';
import { useAccountsQuery } from '../../api/generated';

function UserListTable(): ReactElement | null {
  const { data } = useAccountsQuery();

  return data ? (
    <table>
      <thead className="row">
        <th className={css(styles.header)}>User ID</th>
        <th className={css(styles.header)}>Name of User</th>
        <th className={css(styles.header)}>No. of Sub-Accounts</th>
        {/* <th className={css(styles.header)}>User Role</th> */}
        {/* <th className={css(styles.header)}>Overall Performance</th> */}
        <th className={css(styles.header)}>Registered Email</th>
        <th className={css(styles.header)}>Actions</th>
      </thead>
      <tbody>
        {data?.accounts?.map((user) => (
          <UserRow key={user?.id} user={user} />
        ))}
      </tbody>
    </table>
  ) : null;
}

const styles = StyleSheet.create({
  header: {
    fontWeight: 400,
    color: colors.textSecondary,
    paddingBottom: '0.75rem',
    textAlign: 'left',
    ':last-child': {
      textAlign: 'center',
    },
  },
});

export default UserListTable;
