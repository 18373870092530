import { useIsLoggedInQuery } from './api/generated';
import Layout from './components/shared/Layout';
import SignIn from './pages/SignIn';

function App() {
  const { data } = useIsLoggedInQuery();

  return data?.isLoggedIn ? <Layout /> : <SignIn />;
}

export default App;
