import { IoPauseOutline, IoPlayOutline, IoTrashOutline } from 'react-icons/io5';
import { ReactElement, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import {
  useDeleteSubAccountMutation,
  useToggleSubAccountMutation
} from '../../api/generated';

import DeleteModal from '../shared/DeleteModal';
import { colors } from '../../constants/colors';
import { subAccountsQuery } from '../../graphql/query/subAccount';

interface SubAccountActionsProps {
  active: boolean | undefined;
  subAccountId: number;
  userId: number;
}

function SubAccountActions({
  active,
  subAccountId,
  userId,
}: SubAccountActionsProps): ReactElement {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean | undefined>(
    false
  );

  const [isActive, setIsActive] = useState(active);

  const [toggle, { loading: toggleLoading }] = useToggleSubAccountMutation({
    onError(error) {
      setIsActive(!active);
      console.error(error);
    },
  });

  const [deleteSubAccount] = useDeleteSubAccountMutation({
    onError(error) {
      console.error(error);
    },
    refetchQueries: [{ query: subAccountsQuery }],
  });

  useEffect(() => {
    setIsActive(active || false);
  }, [active]);

  const handleDeleteModal = async () => {
    setShowDeleteModal(true);
  };

  const handleToggle = async () => {
    if (toggleLoading) {
      return;
    }

    setIsActive(!isActive);
    if (subAccountId) await toggle({ variables: { subAccountId } });
  };

  const handleDelete = async () => {
    if (subAccountId && userId) {
      await deleteSubAccount({ variables: { subAccountId, userId } });
    }
  };

  return (
    <>
      <button
        onClick={handleToggle}
        type="button"
        className={css(styles.button)}
      >
        {isActive ? (
          <IoPlayOutline size={25} color={colors.green} />
        ) : (
          <IoPauseOutline size={25} color={colors.icon} />
        )}
      </button>
      <button
        onClick={handleDeleteModal}
        type="button"
        className={css(styles.button)}
        disabled={!isActive}
      >
        <IoTrashOutline
          size={25}
          color={isActive ? colors.danger : colors.icon}
        />
      </button>
      {showDeleteModal ? (
        <DeleteModal
          setShowModal={setShowDeleteModal}
          handleConfirmDelete={handleDelete}
          message=" SubAccount"
        />
      ) : (
        ''
      )}
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    height: 80,
    width: 80,
  },
});

export default SubAccountActions;
