import { css, StyleSheet } from 'aphrodite';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import UserListTable from '../components/userList/UserListTable';
import { CREATE_USER } from '../constants/router';

function UserList(): ReactElement {
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate(CREATE_USER);
  };

  return (
    <div className={`container ${css(styles.container)}`}>
      <div className={css(styles.header)}>
        <span className="sub-heading">User List</span>
        <button onClick={handleCreate} className="btn-primary" type="button">
          Create New User
        </button>
      </div>
      <UserListTable />
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 'calc(100vh - 13rem)',
    maxWidth: '1200px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
});

export default UserList;
