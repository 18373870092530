import { InMemoryCache, makeVar } from '@apollo/client';
import { TOKEN, USER_ROLE } from './constants/storageKeys';

export const isLoggedInVar = makeVar<boolean>(!!localStorage.getItem(TOKEN));

export const accessTokenVar = makeVar<string | null>(
  localStorage.getItem(TOKEN)
);

export const userRoleVar = makeVar<string | null>(
  localStorage.getItem(USER_ROLE)
);

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        accessToken: {
          read() {
            return accessTokenVar();
          },
        },
        userRole: {
          read() {
            return userRoleVar();
          },
        },
      },
    },
  },
});
