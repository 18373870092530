import * as Yup from 'yup';

import { ErrorMessage, Field, Formik } from 'formik';
import { ReactElement, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import {
  TimelineUnit,
  useCreateOrderMutation,
  useSubAccountLeverageQuery
} from '../api/generated';
import { useNavigate, useParams } from 'react-router-dom';

import Derivatives from '../components/createOrder/Derivatives';
import { HOME } from '../constants/router';
import Pairing from '../components/createOrder/Pairing';
import { timeline } from '../constants/timeline';
import { useApolloClient } from '@apollo/client';

const CreateOrderSchema = Yup.object({
  symbol: Yup.string().required('Required').nullable(),
  sellPointpercent: Yup.number()
    .positive('Enter a valid number')
    .required('Required')
    .nullable(),
  quantity: Yup.number()
    .positive('Enter a valid number')
    .required('Required')
    .nullable(),
  minSellPercent: Yup.number()
    .positive('Enter a valid number')
    .required('Required')
    .nullable(),
  derivatives: Yup.string().required('Required').nullable(),
  percentDropPerLadder: Yup.number()
    .positive('Enter a valid number')
    .required('Required')
    .nullable(),
  sellPointDropTimeline: Yup.number()
    .positive('Enter a valid number')
    .required('Required')
    .nullable(),
  sellPointDropPercent: Yup.number()
    .positive('Enter a valid number')
    .required('Required')
    .nullable(),
  ladderPercent: Yup.number()
    .positive('Enter a valid number')
    .required('Required')
    .nullable(),
  buyAboveLiquidationPrice: Yup.number()
    .positive('Enter a valid number')
    .required('Required')
    .nullable(),
  nextTradeShutdown: Yup.boolean(),
  setNumOfCycles: Yup.boolean(),
  numOfCycles: Yup.number().positive().nullable().when('setNumOfCycles', {
    is: (setNumOfCycles:boolean) => setNumOfCycles === true,
    then: Yup.number().nullable().required('Required'),
    otherwise: Yup.number().nullable()
  }),
});

function CreateOrder(): ReactElement {
  const navigate = useNavigate();
  const params = useParams();
  const [flag, setFlag] = useState(false);

  const client = useApolloClient();
  const { data } = useSubAccountLeverageQuery({
    variables: { subAccountId: parseInt(params?.subAccountId || '0', 10) },
  });

  const [mutate, { loading, error }] = useCreateOrderMutation({
    onCompleted({ createOrder }) {
      if (createOrder?.success) {
        client.cache.evict({ fieldName: 'getPositionsBySubAccount' });
        client.cache.gc();
        navigate(HOME);
      }
    },
  });

  const handleSubmit = (values: any) => {
    // console.log('_values', values);
    if (params.subAccountId && data?.subAccount?.leverage) {
      mutate({
        variables: {
          buyAboveLiquidationPrice: parseFloat(values.buyAboveLiquidationPrice),
          derivatives: values.derivatives,
          ladderPercent: parseFloat(values.ladderPercent),
          leverage: data?.subAccount?.leverage,
          minSellPercent: parseFloat(values.minSellPercent),
          nextTradeShutdown: values.nextTradeShutdown,
          percentDropPerLadder: parseFloat(values.percentDropPerLadder),
          activeQuantity: values.quantity,
          sellPointDropPercent: parseFloat(values.sellPointDropPercent),
          sellPointDropTimeline: parseFloat(values.sellPointDropTimeline),
          sellPointPercent: parseFloat(values.sellPointpercent),
          subAccountId: parseInt(params?.subAccountId || '0'),
          symbol: values.symbol,
          sellPointDropTimelineUnit: values.sellPointDropTimelineUnit,
          setNumOfCycles: values.setNumOfCycles,
          numOfCycles: Number(values.numOfCycles),
        },
      });
    }
  };

  return (
    <div className={`container ${css(style.container)}`}>
      <Formik
        initialValues={{
          symbol: null,
          sellPointpercent: null,
          quantity: null,
          minSellPercent: null,
          derivatives: 'linear',
          percentDropPerLadder: null,
          sellPointDropTimeline: null,
          sellPointDropPercent: null,
          ladderPercent: null,
          buyAboveLiquidationPrice: null,
          nextTradeShutdown: false,
          sellPointDropTimelineUnit: TimelineUnit.Minute,
          setNumOfCycles: false,
          numOfCycles: null,
        }}
        validationSchema={CreateOrderSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, setFieldValue }) => (
          <div>
            <div className={css(style.btn_container)}>
              <span className={css(style.top_txt)}>
                {data?.subAccount?.leverage}
                X
              </span>
              {/* <button
            className="btn-primary"
            style={{ marginRight: '1rem' }}
            type="button"
          >
            Isolate
          </button>
          <button className="btn-primary" type="button">
            Buy / Long
          </button> */}
            </div>
            {/* <span className={css(style.wallet_balance)}>
          Wallet Balance:
          <span className={css(style.wallet_amt)}> US$100,000</span>
        </span> */}
            <Derivatives />
            <Pairing />
            <div className="label-container">
              <span className="input-label">Ladder %</span>
              <ErrorMessage
                component="span"
                className="form-error"
                name="ladderPercent"
              />
            </div>
            <Field
              name="ladderPercent"
              className={`input_box ${css(style.input_pass)}`}
              type="text"
            />
            <div className="label-container">
              <span className="input-label">Buy Above Liquidation %</span>
              <ErrorMessage
                component="span"
                className="form-error"
                name="buyAboveLiquidationPrice"
              />
            </div>
            <Field
              name="buyAboveLiquidationPrice"
              className={`input_box ${css(style.input_pass)}`}
              type="text"
            />
            <div className="label-container">
              <span className="input-label">Take Profit %</span>
              <ErrorMessage
                component="span"
                className="form-error"
                name="sellPointpercent"
              />
            </div>
            <Field
              name="sellPointpercent"
              className={`input_box ${css(style.input_pass)}`}
              type="text"
            />
            <div className="label-container">
              <span className="input-label">% Drop / TimeLine</span>
            </div>
            <div className={css(style.dropContainer)}>
              <div className={css(style.drop)}>
                <div className="label-container">
                  <ErrorMessage
                    component="span"
                    className="form-error"
                    name="sellPointDropPercent"
                  />
                </div>
                <Field
                  name="sellPointDropPercent"
                  className={`input_box ${css(
                    style.input_pass,
                    style.dropInput
                  )}`}
                  style={{ width: '233px', marginRight: '17px' }}
                  type="text"
                />
              </div>
              <div className={css(style.drop)}>
                <div className="label-container">
                  <ErrorMessage
                    component="span"
                    className="form-error"
                    name="sellPointDropTimeline"
                  />
                </div>
                <Field
                  name="sellPointDropTimeline"
                  className={`input_box ${css(
                    style.input_pass,
                    style.dropInput
                  )}`}
                  style={{ width: '233px' }}
                  type="text"
                />
              </div>
              <div className={css(style.unit)}>
                <div className="select-wrapper">
                  <Field
                    as="select"
                    name="sellPointDropTimelineUnit"
                    defaultValue={null}
                    className={`input_box ${css(style.input_pass)}`}
                  >
                    {Object.keys(timeline).map((key) => (
                      <option key={key} value={timeline[key]}>
                        {key}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            </div>
            <div className="label-container">
              <span className="input-label">% Drop/Anti-liquidation Trigger</span>
              <ErrorMessage
                component="span"
                className="form-error"
                name="percentDropPerLadder"
              />
            </div>
            <Field
              name="percentDropPerLadder"
              className={`input_box ${css(style.input_pass)}`}
              type="text"
            />
            <div className="label-container">
              <span className="input-label">Minimum Take Profit %</span>
              <ErrorMessage
                component="span"
                className="form-error"
                name="minSellPercent"
              />
            </div>
            <Field
              name="minSellPercent"
              className={`input_box ${css(style.input_pass)}`}
              type="text"
            />
            {/* <div className="label-container">
              <span className="input-label">Number of Cycles</span>
              <ErrorMessage
                component="span"
                className="form-error"
                name="numOfCycles"
              />
            </div>
            <Field
              name="numOfCycles"
              className={`input_box ${css(style.input_pass)}`}
              type="number"
              step={1}
            /> */}
            <div className="label-container">
              <span className="input-label">Run Set Number of Cycles?</span>
              <ErrorMessage
                component="span"
                className="form-error"
                name="setNumOfCycles"
              />
            </div>
            <div className="select-wrapper">
              <select
                // value={values?.derivatives}
                className={`input_box ${css(style.input_pass)}`}
                name="setNumOfCycles"
                onChange={(e) => {
                  // console.log('_e', typeof (e.target.value));
                  if (e.target.value === 'True') {
                    setFlag(true);
                    setFieldValue('setNumOfCycles', true);
                  } else {
                    setFlag(false);
                    setFieldValue('setNumOfCycles', false);

                  }
                }}
              >
                {/* <option value="">Select</option> */}
                <option value="False">False</option>
                <option value="True">True</option>
              </select>
            </div>
            {flag
              ? (
                <>
                  <div className="label-container">
                    <span className="input-label">Number of Cycles</span>
                    <ErrorMessage
                      component="span"
                      className="form-error"
                      name="numOfCycles"
                    />
                  </div>
                  <Field
                    name="numOfCycles"
                    className={`input_box ${css(style.input_pass)}`}
                    type="number"
                    step={1}
                  />
                </>
              )
              : ''}
            <div className={css(style.bottom_sec)}>
              <label className="switch">
                <Field name="nextTradeShutdown" type="checkbox" />
                <span className="slider round" />
              </label>
              <span className={css(style.txt)}>Next Trade ShutDown</span>
            </div>
            <div className={css(style.btn)}>
              <button
                onClick={submitForm}
                className="btn-primary"
                type="button"
                disabled={loading}
              >
                Get Started
              </button>
            </div>
            {error && (
              <div className={`form-error ${css(style.error)}`}>
                {error.message}
              </div>
            )}
          </div>
        )}
      </Formik>
    </div>
  );
}

const style = StyleSheet.create({
  container: {
    width: '35rem',
    padding: '52px 41px',
  },
  btn_container: {
    marginBottom: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  bottom_sec: {
    marginBottom: '5rem',
  },
  wallet_balance: {
    margin: '0px 0px 31px 0px',
    fontSize: '1.2rem',
    lineHeight: '27px',
    color: '#A5ADCF',
    display: 'block',
  },
  txt: {
    fontSize: '18px',
    lineHeight: '27px',
    color: '#A5ADCF',
    marginLeft: '11px',
  },
  top_txt: {
    marginRight: 'auto',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  wallet_amt: {
    marginLeft: '1rem',
    fontSize: '1rem',
    lineHeight: '27px',
    color: '#A5ADCF',
    fontWeight: 'bold',
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    height: '65px',
    width: '160px',
    borderRadius: '100px',
    fontSize: '18px',
  },
  top_button: {
    width: '170px',
    height: '65px',
    display: 'inline-block',
    borderRadius: '100px',
    fontSize: '18px',
  },
  input_pass: {
    marginBottom: '2rem',
    marginTop: '0.5rem',
  },
  label: {
    marginBottom: '81px',
  },
  drop: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    flex: 5,
    ':not(:last-child)': {
      marginRight: '2rem',
    },
  },
  dropContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-end',
  },
  dropInput: {
    marginRight: 0,
    width: '100%',
  },
  error: {
    marginTop: '1rem',
  },
  unit: {
    flex: 4,
  },
});

export default CreateOrder;
