import {
  CreateUserMutationFn,
  UpdateUserMutationFn
} from '../../api/generated';
import { ErrorMessage, Field, Formik } from 'formik';
import { StyleSheet, css } from 'aphrodite';

import { ApolloError } from '@apollo/client';
import { ReactElement } from 'react';
import { colors } from '../../constants/colors';

interface ProfileFormProps {
  isCreate: boolean;
  user?: any;
  mutate: CreateUserMutationFn | UpdateUserMutationFn;
  loading?: boolean | undefined;
  error?: ApolloError | undefined;
  validationSchema: any;
}

function ProfileForm({
  isCreate,
  user,
  mutate,
  loading,
  error,
  validationSchema,
}: ProfileFormProps): ReactElement {
  const handleSubmit = async (values: any) => {
    await mutate({
      variables: isCreate ? values : { userId: user?.id, ...user, ...values },
    });
  };

  return (
    <div className={css(styles.container)}>
      <Formik
        initialValues={
          user || {
            userName: '',
            email: '',
            password: '',
            active: true,
          }
        }
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm }) => (
          <>
            <div className={css(styles.form)}>
              {/* <div>
                {!isCreate && (
                  <div className={css(styles.user, styles.transparent)}>
                    <div>
                      <div className="input-label">Hello,</div>
                      <div className="sub-heading">{user?.userName}</div>
                    </div>
                  </div>
                )}
              </div> */}
              <div className={css(styles.labelContainer)}>
                <span className="input-label">Account Name</span>
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="userName"
                />
              </div>
              <Field
                name="userName"
                className={`input_box ${css(styles.input)}`}
                type="text"
              />
              <div className={css(styles.labelContainer)}>
                <span className="input-label">Email Address</span>
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="email"
                />
              </div>
              <Field
                name="email"
                className={`input_box ${css(styles.input)}`}
                type="email"
              />
              <div className={css(styles.labelContainer)}>
                <span className="input-label">Password</span>
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="password"
                />
              </div>
              <Field
                name="password"
                className={`input_box ${css(styles.input)}`}
              />
            </div>
            <button
              onClick={submitForm}
              disabled={loading}
              className="btn-primary"
              type="button"
            >
              {isCreate ? 'Create' : 'Update'}
            </button>
            {error && (
              <div className={`form-error ${css(styles.error)}`}>
                {error.message}
              </div>
            )}
          </>
        )}
      </Formik>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: '200ms',
  },
  form: {
    marginBottom: '3rem',
    width: '100%',
  },
  input: {
    marginBottom: '2rem',
    marginTop: '0.5rem',
  },
  user: {
    padding: '0.5rem 1rem',
    marginBottom: '2rem',
    borderRadius: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  transparent: {
    backgroundColor: colors.transparent,
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  imgContainer: {
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: '1rem',
    height: '50px',
    position: 'relative',
    cursor: 'pointer',
  },
  createImg: {
    height: '100px',
  },
  icon: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.transparent,
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 25px',
  },
  error: {
    marginTop: '1rem',
  },
});

export default ProfileForm;
