import { TimelineUnit } from '../api/generated';

interface Timeline {
  [key: string]: TimelineUnit;
}

export const timeline: Timeline = {
  Seconds: TimelineUnit.Second,
  Minutes: TimelineUnit.Minute,
  Hours: TimelineUnit.Hour,
  Days: TimelineUnit.Day,
  // Weeks: TimelineUnit.Week,
};
