import { gql } from '@apollo/client';

export const isLoggedIn = gql`
  query isLoggedIn {
    isLoggedIn @client
  }
`;

export const userRole = gql`
  query userRole {
    userRole @client
  }
`;

export const accessToken = gql`
  query accessToken {
    accessToken @client
  }
`;

export const accountsQuery = gql`
  query Accounts {
    accounts {
      id
      userName
      email
      profileImg
      active
      numberOfSubAccounts
      updatedAt
      createdAt
    }
  }
`;

export const accountQuery = gql`
  query Account($userId: Int!) {
    account(userId: $userId) {
      id
      email
      userName
      profileImg
    }
  }
`;

export const viewerQuery = gql`
  query Viewer {
    viewer {
      userName
      email
      profileImg
    }
  }
`;
