import { ApolloClient, HttpLink, from } from '@apollo/client';
import { ErrorHandler, onError } from '@apollo/client/link/error';

import { BASE_URL } from '../constants/environment';
import { ServerError } from '@apollo/client/link/utils';
import { TOKEN } from '../constants/storageKeys';
import { cache } from '../cache';
import { logout } from './logout';
import { setContext } from '@apollo/client/link/context';
import { typeDefs } from '../graphql/client';

const httpLink = new HttpLink({
  uri: BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError((({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      switch (error.extensions.code) {
        case 'UNAUTHENTICATED':
          logout();
          break;
        default:
          console.error(error);
      }
    });
  }

  if (networkError) {
    console.error(networkError);
  }

  if (networkError && (networkError as ServerError).statusCode === 401) {
    logout();
  }
}) as ErrorHandler);

export default new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache,
  typeDefs,
});
