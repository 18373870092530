export const colors = {
  primary: '#246cf9',
  secondary: '#1e1f25',
  icon: '#5D6588',
  white: '#ffffff',
  green: '#30E0A1',
  textSecondary: '#A5ADCF',
  hover: 'rgb(52, 56, 76, 0.8)',
  success: '#11CABE',
  danger: '#FA2256',
  transparent: 'rgb(52, 56, 76, 0.5)',
};
