import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useUserRoleQuery } from '../../api/generated';
import {
  CREATE_ORDER,
  CREATE_USER,
  HOME,
  NEW_TRADE,
  SUB_ACCOUNT_STATUS,
  UPDATE_USER
} from '../../constants/router';
import { ADMIN_ROLE } from '../../constants/userRoles';
import CreateOrder from '../../pages/CreateOrder';
import CreateUser from '../../pages/CreateUser';
import SubAccountStatus from '../../pages/SubAccountStatus';
import UpdateUser from '../../pages/UpdateUser';
import UserList from '../../pages/UserList';
import NewTradeForm from '../trade/NewTradeForm';
import Background from './Background';
import Header from './Header';
import Navbar from './Navbar';

function Layout(): ReactElement {
  const { data } = useUserRoleQuery();

  return (
    <Background>
      <Navbar />
      <Header />
      <div className="main">
        <Routes>
          {data?.userRole === ADMIN_ROLE ? (
            <>
              <Route path={HOME} element={<UserList />} />
              <Route path={CREATE_USER} element={<CreateUser />} />
              <Route path={UPDATE_USER} element={<UpdateUser />} />
            </>
          ) : (
            <>
              <Route path={HOME} element={<SubAccountStatus />} />
              <Route path={NEW_TRADE} element={<NewTradeForm />} />
              <Route path={CREATE_ORDER} element={<CreateOrder />} />
              <Route path={SUB_ACCOUNT_STATUS} element={<div />} />
            </>
          )}
        </Routes>
      </div>
    </Background>
  );
}

export default Layout;
