import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { userReducer } from './user/reducer';

export const store = configureStore({
  reducer: combineReducers({ user: userReducer }),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
