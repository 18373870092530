import { StyleSheet, css } from 'aphrodite';

import { ReactElement } from 'react';
import UserActions from './UserActions';

function Header(): ReactElement {
  return (
    <div className={css(styles.container)}>
      {/* <NotificationIcon /> */}
      <UserActions />
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // width: 'calc(100vw - 5rem)',
    position: 'fixed',
    zIndex: 9,
    right: '5rem',
  },
});

export default Header;
