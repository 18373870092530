import { css, StyleSheet } from 'aphrodite';
import { ReactElement } from 'react';

interface BackgroundProps {
  children: ReactElement[] | ReactElement;
}

function Background({ children }: BackgroundProps): ReactElement {
  return (
    <div className="group group-layout">
      <div
        className="decorator-layout"
        style={{
          background:
            'url(assets/6646f0be456882d2c8e0eba6c1ddaeca.png) center center / contain no-repeat',
        }}
      />
      <div
        className="decorator-layout-1"
        style={{
          background:
            'url(assets/0ab47e76e4b64c73bff7dfa9bddcbb83.png) center center / contain no-repeat',
        }}
      />
      <div
        className="decorator-layout-2"
        style={{
          background:
            'url(assets/cf1de6090825dfc54c9db3bdbc6300a0.png) center center / contain no-repeat',
        }}
      />
      <div className={css(styles.body)}>{children}</div>
    </div>
  );
}

const styles = StyleSheet.create({
  body: {
    display: 'flex',
  },
});

export default Background;
