import { css, StyleSheet } from 'aphrodite';
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSubAccountsQuery } from '../api/generated';
import StatusTable from '../components/subAccount/StatusTable';
import { NEW_TRADE } from '../constants/router';

function SubAccountStatus(): ReactElement {
  const navigate = useNavigate();

  const { data } = useSubAccountsQuery();

  useEffect(() => {
    if (data && data.subAccounts.length === 0) {
      navigate(NEW_TRADE);
    }
  }, [data, navigate]);

  const handleNavigate = () => {
    navigate(NEW_TRADE);
  };

  return (
    <div className={`container ${css(styles.container)}`}>
      <div className={css(styles.headerContainer)}>
        <span className="sub-heading">Account Status</span>
        <button onClick={handleNavigate} className="btn-primary" type="button">
          Add Account
        </button>
      </div>
      <StatusTable />
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 'calc(100vh - 13rem)',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1200px',
    width: '100%',
  },
  headerContainer: {
    paddingBottom: '1.5rem',
    borderBottom: '2px solid #34384C',
    marginBottom: '1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default SubAccountStatus;
