import { css, StyleSheet } from 'aphrodite';
import { ReactElement, useState } from 'react';
import { IoEyeOutline, IoTrashOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteAccountMutation,
  useToggleUserStatusMutation
} from '../../api/generated';
import { colors } from '../../constants/colors';
import { updateUser } from '../../constants/router';
import { accountsQuery } from '../../graphql/query/account';
import DeleteModal from '../shared/DeleteModal';
import Modal from '../shared/Modal';

interface UserRowActionsProps {
  isActive: boolean;
  userId: string;
}

function UserRowActions({
  isActive,
  userId,
}: UserRowActionsProps): ReactElement {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toggle, { loading }] = useToggleUserStatusMutation({
    onError(error) {
      console.error(error);
      setIsChecked(!isChecked);
    },
  });

  const [deleteUser] = useDeleteAccountMutation({
    onError(error) {
      console.error(error);
    },
    refetchQueries: [{ query: accountsQuery }],
  });

  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(isActive);

  const handleCheck = async () => {
    await toggle({ variables: { userId, status: !isChecked } });
    setIsChecked(!isChecked);
    setShowModal(false);
  };

  const handleRedirect = () => {
    navigate(updateUser(userId));
  };

  const handleDelete = () => {
    deleteUser({ variables: { accountId: Number(userId) } });
  };

  const handleDeleteModal = async () => {
    setShowDeleteModal(true);
  };

  return (
    <div className={css(styles.container)}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="switch">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => setShowModal(true)}
          disabled={loading}
        />
        <span className="slider round" />
      </label>
      <IoEyeOutline
        onClick={handleRedirect}
        className={css(styles.details)}
        size={20}
      />
      <IoTrashOutline
        onClick={handleDeleteModal}
        className={css(styles.details)}
        color={colors.danger}
        size={20}
      />
      {showModal ? (
        <Modal
          setShowModal={setShowModal}
          isChecked={isChecked}
          action={handleCheck}
        />
      ) : null}
      {showDeleteModal ? (
        <DeleteModal setShowModal={setShowDeleteModal} handleConfirmDelete={handleDelete} message=" UserAccount" />
      ) : '' }
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    alignItems: 'center',
  },
  details: {
    cursor: 'pointer',
  },
});

export default UserRowActions;
