import { useApolloClient } from '@apollo/client';
import { css, StyleSheet } from 'aphrodite';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useCreateUserMutation } from '../api/generated';
import ProfileForm from '../components/trade/ProfileForm';
import { HOME } from '../constants/router';

const CreateUserSchema = Yup.object({
  userName: Yup.string().required('Required'),
  email: Yup.string().email('Please enter a valid email').required('Required'),
  password: Yup.string()
    .min(8, 'Password must be atleast 8 characters long')
    .matches(/[@$!%*#?&]+/, 'One special character')
    .required('Required'),
});

function CreateUser(): ReactElement {
  const client = useApolloClient();

  const navigate = useNavigate();

  const [mutate, { loading, error }] = useCreateUserMutation({
    onCompleted() {
      navigate(HOME);
      client.cache.evict({ fieldName: 'accounts' });
      client.cache.gc();
    },
  });

  return (
    <div>
      <div className={`container ${css(styles.container)}`}>
        <div className={`sub-heading ${css(styles.title)}`}>Create User</div>
        <ProfileForm
          isCreate
          mutate={mutate}
          validationSchema={CreateUserSchema}
          loading={loading}
          error={error}
        />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '35rem',
    height: '100%',
  },
  title: {
    textAlign: 'center',
    marginBottom: '1.5rem',
  },
});

export default CreateUser;
