import React from 'react';

interface ModalProps {
  setShowModal: any;
  isChecked: any;
  action: () => any;
}

function Modal({ setShowModal, isChecked, action }: ModalProps) {
  return (
    <div className="container_modal">
      <div className="modal">
        <div className="svg-icon">
          <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_966_17218)">
              <path
                d="M40.9817 30.6364C41.3473 29.4585 41.5372 28.2332 41.5453 27V14.2727L26.9998 8.81818L21.2544 10.9636"
                stroke="#F27F16"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.7819 13.7827L12.4546 14.2736V27.0008C12.4546 37.9099 27 45.1827 27 45.1827C30.8492 43.1516 34.3086 40.4555 37.2182 37.219"
                stroke="#F27F16"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 7L47 47"
                stroke="#F27F16"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_966_17218"
                x="0.5"
                y="0.5"
                width="53"
                height="53"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="2.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.94902 0 0 0 0 0.498039 0 0 0 0 0.0862745 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_966_17218"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_966_17218"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div>
          <p style={{ margin: '23px 0px' }}>
            Are you sure you want to
            {isChecked ? ' disable ' : ' enable '}
            Selected User?
          </p>
        </div>
        <div className="modal-button">
          <button
            style={{ width: '110px' }}
            className="btn btn-primary"
            type="button"
            onClick={action}
          >
            Confirm
          </button>
          <button
            style={{
              width: '110px',
              background: '#1d1e28',
              border: '1px solid',
            }}
            className="btn btn-primary"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
