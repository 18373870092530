import { css, StyleSheet } from 'aphrodite';
import { ErrorMessage, useFormikContext } from 'formik';
import { ChangeEventHandler, ReactElement } from 'react';

function Derivatives(): ReactElement {
  const { values, setFieldValue } = useFormikContext<any>();

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setFieldValue('derivatives', e.target.value);
    setFieldValue('symbol', undefined);
    setFieldValue('quantity', null);
  };

  return (
    <>
      <div className="label-container">
        <span className="input-label">Derivatives</span>
        <ErrorMessage
          component="span"
          className="form-error"
          name="derivatives"
        />
      </div>
      <div className="select-wrapper">
        <select
          value={values?.derivatives}
          className={`input_box ${css(style.input_pass)}`}
          name="derivatives"
          onChange={handleChange}
        >
          <option value="linear">USDT Perpetual</option>
          <option value="inverse">Inverse Perpetual</option>
        </select>
      </div>
    </>
  );
}

const style = StyleSheet.create({
  input_pass: {
    marginBottom: '2rem',
    marginTop: '0.5rem',
  },
});

export default Derivatives;
