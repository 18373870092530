import { createReducer } from '@reduxjs/toolkit';
import { userLogin, userLogout } from './actions';

const initialState = {
  isLoggedIn: false,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(userLogin, (state) => {
      state.isLoggedIn = true;
    })
    .addCase(userLogout, (state) => {
      state.isLoggedIn = false;
    });
});
