import {
  ChangeEventHandler,
  ReactElement,
  useMemo,
  useState
} from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { StyleSheet, css } from 'aphrodite';
import { Symbols, useGetSymbolsQuery } from '../../api/generated';

import { colors } from '../../constants/colors';

function Pairing(): ReactElement {
  const { data } = useGetSymbolsQuery();

  const { values, setFieldValue } = useFormikContext<any>();

  const [symbol, setSymbol] = useState<Symbols | undefined | null>(null);

  const pairing = useMemo(() => {
    setSymbol(null);
    return (
      data?.getSymbols?.filter((s) => {
        if (
          s?.alias.includes('USDT')
          && values?.derivatives === 'linear'
        ) {
          return true;
        }
        if (
          values?.derivatives === 'inverse'
          && !s?.alias.includes('USDT')
        ) {
          return true;
        }

        return false;
      }) || []
    );
  }, [data, values.derivatives]);

  const handlePairing: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setFieldValue('symbol', e.target.value);
    const s = data?.getSymbols?.find((sym) => sym?.alias === e.target.value);

    setFieldValue('quantity', s?.lotSizeFilter.minTradeQuanity);
    setSymbol(s);
  };

  return (
    <>
      <div className="label-container">
        <span className="input-label">Pairing</span>
        <ErrorMessage component="span" className="form-error" name="symbol" />
      </div>
      <div className="select-wrapper">
        <select
          name="symbol"
          defaultValue={undefined}
          className={`input_box ${css(style.input_pass)}`}
          value={values.symbol}
          onChange={handlePairing}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <option selected />
          {pairing.map((s) => (
            <option key={s?.alias} value={s?.alias}>
              {s?.name}
            </option>
          ))}
        </select>
      </div>
      <div className="label-container">
        <span className="input-label">Initial Quantity</span>
        <ErrorMessage component="span" className="form-error" name="quantity" />
      </div>
      <Field
        name="quantity"
        className={`input_box ${css(style.input_pass, style.quantity)}`}
        type="number"
        disabled={!values.symbol}
        min={symbol?.lotSizeFilter?.minTradeQuanity || 0}
        max={symbol?.lotSizeFilter?.maxTradeQuantity || 0}
        step={symbol?.lotSizeFilter?.quantityStep || 0}
      />
      <div className={`label-container ${css(style.helperText)}`}>
        {symbol && (
          <>
            min:
            {' '}
            {symbol?.lotSizeFilter?.minTradeQuanity}
            , max:
            {' '}
            {symbol?.lotSizeFilter?.maxTradeQuantity}
            , quantity step:
            {' '}
            {symbol?.lotSizeFilter?.quantityStep}
          </>
        )}
      </div>
    </>
  );
}

const style = StyleSheet.create({
  input_pass: {
    marginTop: '0.5rem',
    marginBottom: '2rem',
  },
  helperText: {
    fontSize: '0.75rem',
    color: colors.textSecondary,
    marginBottom: '2rem',
  },
  quantity: {
    marginBottom: '0.5rem',
  },
});

export default Pairing;
