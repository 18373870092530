import { StyleSheet, css } from 'aphrodite';
import {
  useSubAccountsActiveQuery,
  useSubAccountsQuery
} from '../../api/generated';

import StatusRow from './StatusRow';

function StatusTable() {
  const { data: subAccountsData, loading: subAccountsLoading } = useSubAccountsQuery();
  const { data: activeData, loading: activeLoading } = useSubAccountsActiveQuery({
    pollInterval: 20000
  });

  return (
    <div className={css(style.container)}>
      {!(subAccountsLoading
        && activeLoading)
        && subAccountsData?.subAccounts.map((row, idx) => (
          <StatusRow
            key={row?.id}
            active={activeData?.subAccounts[idx]?.active}
            row={row}
          />
        ))}
    </div>
  );
}

const style = StyleSheet.create({
  container: {
    overflowY: 'scroll',
    height: 'contain',
  },
});

export default StatusTable;
