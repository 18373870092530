export const HOME = '/';
export const NEW_TRADE = '/new-trade';
export const WALLET = '/wallet';
export const SUB_ACCOUNT_STATUS = '/sub-account-status';
export const SIGNIN = '/sign-in';
export const CREATE_USER = '/create-user';
export const UPDATE_USER = '/update-user/:userId';
export const CREATE_ORDER = '/create-order/:subAccountId';
export const updateUser = (userId: string) => `/update-user/${userId}`;
export const createOrder = (subAccountId?: string) => `/create-order/${subAccountId}`;
