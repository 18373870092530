import { gql } from '@apollo/client';

export const subAccountsQuery = gql`
  query SubAccounts {
    subAccounts {
      id
      accountId
      leverage
      subAccountName
      byBitKey
      byBitSecret
      createdAt
      updatedAt
    }
  }
`;

export const subAccountsActiveQuery = gql`
  query SubAccountsActive {
    subAccounts {
      active
    }
  }
`;

export const subAccountLeverage = gql`
  query SubAccountLeverage($subAccountId: Int!) {
    subAccount(subAccountId: $subAccountId) {
      leverage
    }
  }
`;
