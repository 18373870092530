import { StyleSheet, css } from 'aphrodite';

import { ReactElement } from 'react';
import { useViewerQuery } from '../../api/generated';

function UserActions(): ReactElement | null {
  const { data, loading } = useViewerQuery();

  return !loading ? (
    <div className={css(styles.container)}>
      <span>{data?.viewer.userName}</span>
      {/* <img
        className={css(styles.img)}
        src="https://i.pravatar.cc/50?img=60"
        alt="user"
      /> */}
      {/* <IoChevronDownOutline size={20} /> */}
    </div>
  ) : null;
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    marginLeft: '1rem',
    cursor: 'pointer',
  },
});

export default UserActions;
