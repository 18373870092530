import { StyleSheet, css } from 'aphrodite';

import Loader from '../shared/Loader';
import PositionRow from './PositionRow';
import { ReactElement } from 'react';
import { useGetPositionsBySubAccountQuery } from '../../api/generated';

interface PositionTableProps {
  subAccountId: number;
}

export function PositionTable({
  subAccountId,
}: PositionTableProps): ReactElement {
  const { data, loading } = useGetPositionsBySubAccountQuery({
    variables: { subAccountId },
    pollInterval: 5 * 60 * 1000,
  });

  return loading ? (
    <div className={css(styles.loader)}>
      <Loader />
    </div>
  ) : data?.getPositionsBySubAccount
    && data?.getPositionsBySubAccount?.length > 0 ? (
      <div className={css(styles.walletTable, styles.expandedTable)}>
        <div className={css(styles.walletRow)}>
          <span className={css(styles.headerText, styles.rowItem)}>Pairing</span>
          <span
            className={css(styles.headerText, styles.rowItem, styles.decimals)}
          >
            Active Quantity
          </span>
          <span
            className={css(styles.headerText, styles.rowItem, styles.decimals)}
          >
            Entry Price
          </span>
          <span
            className={css(styles.headerText, styles.rowItem, styles.decimals)}
          >
            Anti-Liquidation Price
          </span>
          <span
            className={css(styles.headerText, styles.rowItem, styles.decimals)}
          >
            Exit Price
          </span>
          <span
            className={css(styles.headerText, styles.rowItem)}
          >
            Next Trade Shutdown
          </span>
        </div>
        {data?.getPositionsBySubAccount?.map((item) => (
          <PositionRow key={item?.symbol} position={item} />
        ))}
      </div>
    ) : (
      <div className={css(styles.loader)}>No Data Available</div>
    );
}

const styles = StyleSheet.create({
  walletTable: {
    padding: '2rem 0rem',
    display: 'none',
  },
  expandedTable: {
    display: 'block',
  },
  rowItem: {
    flex: 1,
    padding: '0 20px',
  },
  headerText: {
    fontWeight: 'bold',
  },

  walletRow: {
    display: 'flex',
    alignItems: 'center',
    ':not(:last-child)': {
      marginBottom: '1rem',
    },
    fontSize: '15px'
  },
  icon: {
    transform: 'rotate(0deg)',
    transition: 'transform 200ms',
  },
  activeIcon: {
    transform: 'rotate(-180deg)',
  },
  loader: {
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center',
  },
  decimals: {
    textAlign: 'right',
  },
  alignItem: {
    textAlign: 'center'
  }
});

export default PositionTable;
